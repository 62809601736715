import Api from '../Api'

const KepalaKategori = (props) => {
    let k = props.item
    return (
        <div>
            <div className='item-kepala-kategori'>
                <div className='my-3' />
                <div className='grid grid-cols-2'>
                    <div className='uppercase font-bold'>
                        {k.nama}
                    </div>
                    <div className='text-right'>
                        <a href='/daftar-barang' className='text-blue-900 font-bold'>
                            {'>> Lihat Semua'}
                        </a>
                    </div>
                </div>
                <div className='my-3' />
                <div className='image-kepala-kategori'>
                    <img src={Api.getBaseUrl() + 'fotokategori2/' + k.id} className='w-full' alt='test'/>
                </div>
            </div>
        </div>
    )
}

export default KepalaKategori