import HomeView from './views/HomeView'
import DaftarBarangView from './views/DaftarBarangView'
import DetailBarangView from './views/DetailBarangView'
import Login from './views/Login'
import Layout from './views/Layout'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/daftar-barang/'>
              <DaftarBarangView/>
            </Route>
            <Route path='/detail-barang/:id'>
              <DetailBarangView/>
            </Route>
            <Route exact path='/'>
              <HomeView />
            </Route>
          </Switch>
        </Layout>      
      </Router>
    </div>
  );
}

export default App;
