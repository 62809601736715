import {Swiper, SwiperSlide} from 'swiper/react'
import ItemBarang from '../components/ItemBarang'

const DaftarBarang = (props) => {
    return (
        <div className='w-full h-full'>
            <div className="font-bold uppercase text-2xl text-brand text-center">
                {props.judul}
            </div>
            <div className='my-3' />
            <div className='produk-terbaru' style={{height: 600}}>
                <Swiper
                    className='h-full w-full'
                    slidesPerView={2}
                    slidesPerColumn={2}
                    spaceBetween={5}
                    observer={true}
                    breakpoints={
                        {
                            // when window width is >= 320px
                            320: {
                              slidesPerView: 2,
                            },
                            // when window width is >= 480px
                            480: {
                              slidesPerView: 3,
                            },
                            // when window width is >= 640px
                            640: {
                              slidesPerView: 4,
                            },
                            820: {
                                slidesPerView: 4,
                            },
                            1024: {
                                slidesPerView: 7,
                            }
                        }
                    } 
                >
                    {
                        props.daftar.map((x, idx)=>{
                            return (
                                <SwiperSlide key={x.id} style={{height: 280}}>
                                    <div>
                                        <ItemBarang barang={x}/>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default DaftarBarang