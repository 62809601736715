import Pagination from '@material-ui/lab/Pagination';
import { useRef, useEffect, useState } from 'react';
import Api from '../Api';
import ItemBarang from '../components/ItemBarang';
import { Fab, Icon, makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom';

import './DaftarBarang.css'

const { List, ListItem, ListItemText, Breadcrumbs, Link, Backdrop, CircularProgress } = require("@material-ui/core")

const itemPerPage = 60

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    fabDown: {
        position: 'absolute',
        bottom: 10,
        right: 10
    },
    fabUp: {
        position: 'absolute',
        bottom: 10,
        right: 10
    }
}));

const DaftarBarangView = (props) => {
    let location = useLocation()
    let param = new URLSearchParams(location.search)
    const classes = useStyles();
    // const {cari} = useParams()
    let cari = param.get("cari") || ""
    const [busy, setBusy] = useState(false)
    const [daftarKategori, setDaftarKategori] = useState([])
    const [daftar, setDaftar] = useState([])
    const [page, setPage] = useState(param.get("page") || 1)
    const [totalPages, setTotalPages] = useState(1)
    const [selectedKategoriId, setSelectedKategoriId] = useState(param.get("kategoriid"))
    const daftarKategoriRef = useRef(null)
    const [gudang, setGudang] = useState(Api.getGudangId())
    const [daftarGudang, setDaftarGudang] = useState([])

    const handleGudangChange = (e) => {
        let s = e.target.value.toLowerCase()
        Api.setGudangId(s)
        setGudang(s)
        fetchData()
    }
    
    const RenderPilihanGudang = () => {
        return (
            <div className="mx-auto" style={{maxWidth: 325}}>
                <div className="relative inline-block w-full text-gray-700">
                    <select value={gudang} onChange={handleGudangChange} className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline" placeholder="Regular input">
                        {
                            daftarGudang.map(x=>{
                                return <option key={x.id} value={x.id}>{x.nama}</option>
                            })
                        }
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                    </div>
                </div>
            </div>
        )
    }

    const renderNamaKategori1 = () => {
        let temp = daftarKategori.filter(x=>x.id === selectedKategoriId)
        let nama = ""
        if(temp.length > 0) {
            nama = temp[0].nama
        }
        return (
            <div>
                {nama}
            </div>
        )
    }
    const renderNamaKategori2 = () => {
        let temp = daftarKategori.filter(x=>x.id === selectedKategoriId)
        let nama = ""
        if(temp.length > 0) {
            nama = temp[0].kategori2
        }
        return (
            <div>
                {nama}
            </div>
        )
    }

    const fetchData = async()=>{
        setBusy(true)
        try {
            let kategoriId = -1
            let sort = 'created desc'
            if(selectedKategoriId) {
                kategoriId = selectedKategoriId
                sort = 'nama asc'
            }
            let api = await Api.getDaftarBarang((cari || ''), kategoriId, -1, ((page-1) * itemPerPage), itemPerPage, sort, Api.getGudangId())
            if(api.ok) {
                let daftarBarang = api.data.data
                let totalCount = api.data.totalCount
                setDaftar(daftarBarang)
                setTotalPages(Math.ceil(totalCount/itemPerPage))
            } else {
                alert('server error')
            }

            api = await Api.getDaftarKategori()
            if(api.ok) {
                setDaftarKategori(api.data)
            } else {
                alert('server error')
            }
            
            window.scrollTo(0, 0)

        } catch (er) {
            console.error(er)
        }
        setBusy(false)
    }

    useEffect(()=>{
        async function test() {
            let dg = await Api.getDaftarGudang(Api.getTokoId())
            setDaftarGudang(dg)
            if(dg.length > 0) {
                if(Api.getGudangId() === -1) {
                    Api.setGudangId(dg[0].id)
                }
            }
            await fetchData()
        }
        test()
        // fetchData()

    }, [page, selectedKategoriId])

    const scrollDistance = 50
    const scrollUp = () => {
        daftarKategoriRef.current.scrollTop -= scrollDistance
    }
    
    const scrollDown = () => {
        daftarKategoriRef.current.scrollTop += scrollDistance
    }

    return (
        <div className='p-2'>
            <Backdrop className={classes.backdrop} open={busy}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='my-3' />
            <div className="my-3">
                <RenderPilihanGudang />
            </div>
            <div className='breadcrumb uppercase'>
                <Breadcrumbs>
                    <Link href='/'>Home</Link>
                    {
                        selectedKategoriId && (
                            <Link href='/daftar-barang'>{renderNamaKategori2()}</Link>
                        )
                    }
                    {
                        selectedKategoriId && (
                            <Link href='/daftar-barang'>{renderNamaKategori1()}</Link>
                        )
                    }
                </Breadcrumbs>
            </div>
            <div className='my-3' />
            <div>
                {
                    (cari && cari.length > 0) && (
                        <span>Pencarian: "{cari}" <a href='/daftar-barang' className='ml-3 text-yellow-500'><i className='fa fa-times mr-1' />[clear]</a></span>
                    )
                }
            </div>
            <div className='my-3' />
            <hr className="md:hidden lg:hidden 2xl:hidden"/>
            <div className='block lg:flex 2xl:flex'>
                <div className="flex max-h-72 md:max-h-full lg:max-h-full" style={{maxWidth: 400}}>
                    <div className='flex-grow'>
                        <List ref={daftarKategoriRef} style={{maxHeight: '100%', overflow: 'auto'}}>
                            {
                                daftarKategori.map((itm)=>{
                                    return (
                                        <ListItem selected={(itm.id === selectedKategoriId)} button key={itm.id} onClick={() => {
                                            window.location.href = "/daftar-barang?kategoriid=" + itm.id
                                            // setPage(1)
                                            // setSelectedKategoriId(itm.id)
                                        }}>
                                            <ListItemText>{itm.nama}</ListItemText>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </div>
                    <div className='flex flex-col max-h-full flex-grow-0' style={{width: 50}}>
                        <div className="flex-grow-0 mt-1">
                            <Fab size="small" color='primary' onClick={()=>{scrollUp()}}>
                                <Icon className='fa fa-chevron-up' />
                            </Fab>
                        </div>
                        <div className='flex-grow'></div>
                        <div className="flex-grow-0 mb-1">
                            <Fab size="small" color='primary' onClick={()=>{scrollDown()}} >
                                <Icon className='fa fa-chevron-down' />
                            </Fab>
                        </div>
                    </div>
                </div>
                <hr className="md:hidden lg:hidden 2xl:hidden"/>
                <div className='my-3 lg:hidden 2xl:hidden' />
                <div className='lg:flex-grow 2xl:flex-grow lg:px-2 2xl:px-2'>
                    <div className='pagination mb-2'>
                        {
                            totalPages > 1 && (
                                <Pagination page={page} count={totalPages} onChange={(e, value)=>{setPage(value)}} variant="outlined" color="primary" />
                            )
                        }
                    </div>
                    <div className='daftar-barang grid gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7'>
                        {
                            daftar.map((itm)=>{
                                return (
                                    <ItemBarang key={itm.id} barang={itm}/>
                                )
                            })
                        }
                    </div>
                    <div className='my-3' />
                    <div className='pagination'>
                        {
                            totalPages > 1 && (
                                <Pagination page={page} count={totalPages} onChange={(e, value)=>{setPage(value)}} variant="outlined" color="primary" />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DaftarBarangView